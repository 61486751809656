import React from 'react';
import MobileDetect from 'mobile-detect';

const HeaderImage = () => {

    const md = new MobileDetect(window.navigator.userAgent);

    const isMobile = md.mobile() !== null;

    return (
        <div className="w-full lg:h-[25vw] md:h-[39vw] h-[60vw] m-auto bg-gray-200">
            {isMobile ?
                <img src={process.env.REACT_APP_URL_IMAGE_HEADER_MOVILE} alt="Imagen de header" className="object-stretch w-full h-full" />
                :
                <img src={process.env.REACT_APP_URL_IMAGE_HEADER_PC} alt="Imagen de header" className="object-stretch w-full h-full" />
            }
        </div>
    );
}

export default HeaderImage;

//https://nikearprod.vtexassets.com/assets/vtex.file-manager-graphql/images/fe45c127-8f80-4080-ae43-2b566f51c62c___ff2366432481da6c3f805ae17969feed.png
//https://nikearprod.vtexassets.com/assets/vtex.file-manager-graphql/images/035d7b78-3f51-4033-bb99-e8410db2b6aa___508f04efd31e7f23b41266ea7f808c50.jpg