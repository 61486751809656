import React, { useState } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const FaqItem = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.5 }}
            className="mb-4"
        >

            <div className="flex items-center cursor-pointer mb-2" onClick={toggleExpansion}>
                <motion.div
                    className="mr-2"
                    initial={false}
                    animate={{ rotate: expanded ? 90 : 0 }}
                    transition={{ type: 'spring', stiffness: 100, damping: 10 }}
                >
                    {expanded ? <FiX className="text-red-500" /> : <FiPlus className={"text-green-500"} />}
                </motion.div>
                <h3 className="text-lg font-semibold">{question}</h3>
            </div>
            <motion.div
                initial={false}
                animate={{ opacity: expanded ? 1 : 0, height: expanded ? 'auto' : 0 }}
                transition={{ duration: 0.2 }}
            >
                {expanded && <p className="mt-2 mb-4">{answer}</p>}
            </motion.div>
            <hr className="border-t border-gray-400" />


        </motion.div>
    );
}

export default FaqItem;