import React, { useEffect } from 'react';
import Header from './components/header/Header';
import HeaderImage from './components/header/HeaderImage';
import ProductList from './components/items/ProductList';
import Footer from './components/footer/Footer';
import ProductsSection from './components/items/ProductsSection';
import FaqSection from './components/faq/FaqSection';
import TermsAndConditions from './components/termsAndConditions/termsAndConditions';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RedirectToHome from './components/utils/redirecToHome';
import ScrollToTop from './components/utils/ScrollTotop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<RedirectToHome />} />
        <Route path="/home/:lng" element={<Home />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
      </Routes>
    </Router>
  );
}

const Home = () => {

  const { lng } = useParams();
  const { i18n, t } = useTranslation("home");

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <HeaderImage />
      <ProductsSection />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <ProductList />
      </main>
      <FaqSection />
      <Footer />
    </div>
  );
}

const Terms = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow my-20 lg:mx-20 md:mx-8 sm:mx-4 mx-4">
        <TermsAndConditions />
      </main>
      <Footer />
    </div>

  );
}

export default App;