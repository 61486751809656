import { useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signOut, onAuthStateChanged, signInWithRedirect, getRedirectResult, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import MobileDetect from 'mobile-detect';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { t } from 'i18next';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATA_BASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASIREMENT_ID
};

export const app = initializeApp(firebaseConfig);

// eslint-disable-next-line no-unused-vars
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_URL_RECAPTCHAT_KEY),
    isTokenAutoRefreshEnabled: true
});

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const auth = getAuth();

export const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.mobile() !== null;

    try {
        if (!isMobile) {
            await signInWithPopup(auth, provider);
        } else {
            await signInWithRedirect(auth, provider);
        }
    } catch (error) {
        console.error("Error al iniciar sesión con Google:", error);
    }
}

export const getRedirect = async () => {
    try {
        const result = await getRedirectResult(auth);

        // eslint-disable-next-line no-unused-vars
        const credential = GoogleAuthProvider.credentialFromResult(result);

        const user = result.user;

        const userLanguage = navigator.language || navigator.userLanguage;
        const mainLanguage = userLanguage.split('-')[0];

        await fetch(process.env.REACT_APP_URL_CREATE_ACCOUNT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userEmail: user.email,
                userName: user.displayName,
                idioma: mainLanguage,
                uid: user.uid,
            })
        });

    } catch (error) {
        // console.error("Error al iniciar sesión con Google:", error);
    }
}

export const logout = async () => {
    try {
        await signOut(auth).then(() => {
            console.log('Sesion cerrada');
        }).catch((error) => {
            console.log('Error al cerrar sesion: ' + error);
        });
    } catch (error) {
        console.error(error.message);
    }
}

export const useAuth = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    return { user };
}

export const userData = async (callback) => {
    try {
        const user = auth.currentUser;

        if (user) {
            await fetch(process.env.REACT_APP_URL_POST_USER_DATA, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        callback(data.user);
                    } else {
                        callback(null);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback(null);
                });
        }
    } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
        callback(null);
    }
}

export const faq = async (callback, { lng }) => {
    try {

        if (!lng || lng.length > 2) return;
        
        await fetch(process.env.REACT_APP_URL_POST_USER_FAQ, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                language: lng,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    callback(data.faq);
                }
            })
            .catch((error) => {
                console.log(error);
                callback(null);
            });
    } catch (error) {
        console.error('Error al obtener datos del documento:', error);
        callback(null);
    }
}

export const items = async (callback, { lng }) => {
    try {

        if (!lng || lng.length > 2) return;

        await fetch(process.env.REACT_APP_URL_POST_USER_ITEMS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                language: lng,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    callback(data.items);
                }
            })
            .catch((error) => {
                console.log(error);
                callback(null);
            });
    } catch (error) {
        console.error('Error al obtener datos del documento:', error);
        callback(null);
    }
}

export const itemStock = async (callback, { id }) => {
    try {
        await fetch(process.env.REACT_APP_URL_POST_USER_ITEM_STOCK, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    callback(data.stock);
                }
            })
            .catch((error) => {
                console.log(error);
                callback(null);
            });
    } catch (error) {
        console.error('Error al obtener datos del documento:', error);
        return null;
    }
}

export const setFormCanje = async (callback, { formData, newPoints, backPoints, idProduct, nameProduct }) => {
    try {
        const user = auth.currentUser;

        if (user) {
            await fetch(process.env.REACT_APP_URL_POST_USER_SET_FORM_CANJE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    formData: formData,
                    newPoints: newPoints,
                    backPoints: backPoints,
                    idProduct: idProduct,
                    nameProduct: nameProduct
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        callback(data.success);
                    } else {
                        callback(data.success);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback(false);
                });
        }
    } catch (error) {
        console.error('Error al agregar documento:', error);
        callback(false);
    }
}