const TermsAndConditions = () => {
    return (
        <div className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold mb-4 text-center">Términos y Condiciones de Uso de la Tienda Deportsen</h1>
            <p className="mb-4 text-center">Fecha de entrada en vigor: 15/05/2024</p>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">1. Introducción</h2>
                <p>
                    Estos Términos y Condiciones rigen el uso de la aplicación Deportsen ("la App") y la acumulación y canje de Sencoins, la moneda virtual de la App. Al descargar y utilizar la App desde la Play Store, usted acepta estos Términos y Condiciones en su totalidad. Si no está de acuerdo con alguna parte de estos términos, no debe usar la App.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">2. Definiciones</h2>
                <ul className="list-disc list-inside">
                    <li><strong>Sencoins:</strong> Moneda virtual acumulable dentro de la App.</li>
                    <li><strong>Usuarios:</strong> Personas registradas en la App.</li>
                    <li><strong>Usuarios Premium:</strong> Usuarios que han pagado una suscripción premium dentro de la App.</li>
                    <li><strong>Ejercicios:</strong> Actividades y entrenamientos disponibles en la App.</li>
                    <li><strong>Canje:</strong> Proceso de intercambio de Sencoins por productos en la tienda www.deportsen.store ("la Store").</li>
                    <li><strong>Sitios Oficiales:</strong> Plataformas autorizadas por la App para la comunicación y anuncios oficiales.</li>
                </ul>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">3. Acumulación de Sencoins</h2>
                <p><strong>3.1 Realización de Ejercicios:</strong> Los usuarios acumulan Sencoins al completar ejercicios dentro de la App. La cantidad de Sencoins ganados puede variar según la dificultad y el tipo de ejercicio. Es necesario tener una conexión a Internet activa para acumular Sencoins.</p>
                <p><strong>3.2 Ingresos Diarios:</strong> Los usuarios pueden ganar Sencoins adicionales simplemente ingresando a la App cada día. La cantidad de Sencoins otorgados puede variar. Es necesario tener una conexión a Internet activa para acumular Sencoins.</p>
                <p><strong>3.3 Usuarios Premium:</strong> Los usuarios premium recibirán una bonificación doble de Sencoins. Por ejemplo, si un usuario no premium gana 3 Sencoins por un ejercicio o ingreso diario, un usuario premium ganará 6 Sencoins en las mismas condiciones.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">4. Canje de Sencoins</h2>
                <p><strong>4.1 Proceso de Canje:</strong> Los usuarios pueden canjear sus Sencoins por productos disponibles en la Store, siempre que tengan suficientes Sencoins para completar la transacción y haya stock disponible en la Store. El stock es limitado y está sujeto a disponibilidad.</p>
                <p><strong>4.2 Precios Variables:</strong> La cantidad de Sencoins necesaria para canjear un producto puede variar. Esto incluye aumentos en el precio, descuentos y rebajas.</p>
                <p><strong>4.3 Revisión de Canjes:</strong> Cada canje será revisado para detectar posibles irregularidades. En caso de detectar cualquier actividad sospechosa o fraudulenta, la App se reserva el derecho de anular el canje y dar de baja la cuenta del usuario implicado.</p>
                <p><strong>4.4 Problemas de Logística:</strong> En caso de no poder entregar los productos canjeados debido a problemas de logística que impidan el envío a la localidad del usuario, se le hará entrega de dinero en dólares por el valor del producto.</p>
                <p><strong>4.5 Cancelaciones y Devoluciones:</strong> Si se detecta un error en el stock o un bug en la página que afecte el canje, se anulará el canje y se devolverán los Sencoins al usuario.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">5. Limitaciones y Restricciones</h2>
                <p><strong>5.1 Acumulación Limitada a la App:</strong> Los Sencoins solo pueden ganarse a través de actividades dentro de la App o mediante anuncios oficiales en los sitios autorizados de la App. No se otorgarán Sencoins por ningún otro medio.</p>
                <p><strong>5.2 Uso de la Cuenta:</strong> Un usuario puede canjear Sencoins tantas veces como desee, siempre que disponga de suficientes Sencoins en su cuenta y haya stock disponible en la Store.</p>
                <p><strong>5.3 No Transferibilidad:</strong> Los Sencoins no son transferibles entre cuentas de usuarios.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">6. Sanciones por Incumplimiento</h2>
                <p><strong>6.1 Actividades Fraudulentas:</strong> Cualquier intento de manipulación, fraude o uso indebido de los Sencoins resultará en la anulación del canje y la terminación de la cuenta del usuario infractor.</p>
                <p><strong>6.2 Revisión y Suspensión:</strong> La App se reserva el derecho de revisar todas las actividades de los usuarios y suspender cuentas que se consideren involucradas en prácticas indebidas.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">7. Cambios y Modificaciones</h2>
                <p>La App se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Las modificaciones serán efectivas inmediatamente después de su publicación en la App. El uso continuado de la App después de cualquier cambio constituye la aceptación de los nuevos Términos y Condiciones.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">8. Limitación de Responsabilidad</h2>
                <p>La App y sus desarrolladores no serán responsables de ningún daño directo, indirecto, incidental o consecuente que resulte del uso o la incapacidad de uso de los Sencoins o la App, incluidos, entre otros, daños por pérdida de beneficios, uso, datos u otros intangibles.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">9. Ley Aplicable</h2>
                <p>Estos Términos y Condiciones se rigen e interpretan de acuerdo con las leyes del país en el que la App tiene su sede principal, sin tener en cuenta sus conflictos de disposiciones legales.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">10. Contacto</h2>
                <p>Para cualquier consulta o aclaración sobre estos Términos y Condiciones, puede contactarnos a través de <a href="mailto:contact@deportsen.com" className="text-blue-600">contact@deportsen.com</a>.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">11. Idioma y Disponibilidad</h2>
                <p><strong>11.1 Idioma:</strong> La Tienda y sus términos y condiciones están disponibles únicamente en español.</p>
                <p><strong>11.2 Disponibilidad:</strong> Actualmente, la App solo está disponible para descarga en la Play Store.</p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">12. Términos y Condiciones de la App y la Store</h2>
                <p><strong>12.1 Términos de la App:</strong> Los términos y condiciones específicos del uso de la App están disponibles en otro sitio web <a href="https://www.deportsen.com" className="text-blue-600">www.deportsen.com</a>.</p>
                <p><strong>12.2 Términos de la Store:</strong> Estos términos y condiciones se aplican exclusivamente al uso de la Store (<a href="https://www.deportsen.store" className="text-blue-600">www.deportsen.store</a>) y son el único sitio disponible para el canje de Sencoins.</p>
            </section>
        </div>
    );
};

export default TermsAndConditions;