import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFormCanje } from '../../firebase/firebaseConfig'; // Asegúrate de importar tu app de Firebase
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const Formulario = ({ puntosProducto, puntosUsuario, idProduct, nameProduct, setRefreshPoints, refrshPoints }) => {

    const { t } = useTranslation("home");
    
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        numeroContacto: '',
        pais: '',
        provincia: '',
        ciudad: '',
        codigoPostal: '',
        direccion: '',
        numero: '',
    });

    const [sendForm, setSendForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.trim()
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSendForm(true);

        try {

            await setFormCanje((status) => {
                setSuccess(status);
                setRefreshPoints(!refrshPoints)
            }, {
                formData: formData,
                newPoints: (puntosUsuario - puntosProducto),
                backPoints: puntosUsuario,
                idProduct: idProduct,
                nameProduct: nameProduct,
            });

        } catch (error) {
            console.error('Error al agregar documento:', error);

            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='container mx-auto mt-10 overflow-y-auto'>

            {!sendForm && !loading &&
                (<form onSubmit={handleSubmit} className="max-w-lg mx-auto mt-10 p-5">
                    <div className="grid grid-cols-1 gap-6">
                        {/* Inputs del formulario */}
                        {/* Nombre */}
                        <div>
                            <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">{t("texto_form_nombre")}</label>
                            <input type="text" name="nombre" id="nombre" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Apellido */}
                        <div>
                            <label htmlFor="apellido" className="block text-sm font-medium text-gray-700">{t("texto_form_apellido")}</label>
                            <input type="text" name="apellido" id="apellido" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Numero de contacto */}
                        <div>
                            <label htmlFor="numeroContacto" className="block text-sm font-medium text-gray-700">{t("texto_form_numero_contacto")}</label>
                            <input type="tel" name="numeroContacto" id="numeroContacto" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* País */}
                        <div>
                            <label htmlFor="pais" className="block text-sm font-medium text-gray-700">{t("texto_form_pais")}</label>
                            <input type="text" name="pais" id="pais" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Provincia/Estado */}
                        <div>
                            <label htmlFor="provincia" className="block text-sm font-medium text-gray-700">{t("texto_form_provincia_estado")}</label>
                            <input type="text" name="provincia" id="provincia" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Ciudad */}
                        <div>
                            <label htmlFor="ciudad" className="block text-sm font-medium text-gray-700">{t("texto_form_ciudad")}</label>
                            <input type="text" name="ciudad" id="ciudad" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Código Postal */}
                        <div>
                            <label htmlFor="codigoPostal" className="block text-sm font-medium text-gray-700">{t("texto_form_cp")}</label>
                            <input type="text" name="codigoPostal" id="codigoPostal" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Dirección */}
                        <div>
                            <label htmlFor="direccion" className="block text-sm font-medium text-gray-700">{t("texto_form_direccion")}</label>
                            <input type="text" name="direccion" id="direccion" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>
                        {/* Número */}
                        <div>
                            <label htmlFor="numero" className="block text-sm font-medium text-gray-700">{t("texto_form_numero")}</label>
                            <input type="text" name="numero" id="numero" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-md sm:text-sm border-gray-300 rounded-md p-2" onChange={handleChange} required />
                        </div>

                        {/* Botón de enviar */}
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="text-black font-semibold py-2 px-4 rounded"
                                style={{ backgroundColor: '#FFC300' }}
                                onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                            >
                                {t("texto_form_guardar")}
                            </button>
                        </div>
                    </div>
                </form>)
            }

            {loading && sendForm && (
                <div className="max-w-lg mx-auto m-20 p-20">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                </div>
            )}

            {success && !error && sendForm && !loading ? (
                <div className="max-w-lg mx-auto m-20 py-20 px-10">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Alert variant="outlined" severity="success">
                            {t("texto_felicidad_canje")}
                        </Alert>

                    </Box>
                </div>
            )
                :
                (
                    <>
                        {sendForm && !loading && (
                            <div className="max-w-lg mx-auto m-20 py-20 px-10">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Alert variant="outlined" severity="error">
                                        {t("texto_error_canje")}
                                    </Alert>

                                </Box>
                            </div>
                        )}
                    </>
                )
            }

            {error && !success && sendForm && !loading && (
                <div className="max-w-lg mx-auto m-20 py-20">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Alert variant="outlined" severity="error">
                            {t("texto_error_canje")}
                        </Alert>

                    </Box>
                </div>
            )}

        </div>
    );
};

export default Formulario;
