import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, signInWithGoogle, userData, itemStock } from '../../firebase/firebaseConfig'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { MdClose, MdNotInterested, MdArrowBack } from 'react-icons/md';
import Formulario from './FormModal';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const ProductModal = ({ product, close }) => {

    const { t } = useTranslation(["home", "header"]);

    const isPointEmpty = (point) => {
        return point.trim() === '';
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { user } = useAuth();

    const [userDate, setUserData] = useState(null);

    const [newUser, setNewUser] = useState(false);

    const [userDatePoints, setUserPoints] = useState(null);

    const [refrshPoints, setRefreshPoints] = useState(false);

    useEffect(() => {
        if (user) {
            userData((userData) => {
                if (userData) {
                    setUserData(userData);
                    setUserPoints(userData.userPoints.points);
                } else {
                    console.log('El usuario no existe');
                    setNewUser(true);
                }
            });
        }
    }, [user, refrshPoints]);

    const options = { useGrouping: true, maximumFractionDigits: 0 }; // Lo usamos para poner el punto por cada mil pj: 1.000 10.000 100.000

    const puntosDisponibles = userDatePoints && userDatePoints.toLocaleString(undefined, options);
    const puntosProducto = product.points.toLocaleString(undefined, options);

    const offer = product.offer ? product.offer : 0;
    const precioOferta = product.offer ? product.points - (product.points * (offer / 100)) : 0;


    const [canje, setCanje] = useState(false);

    const [alertStock, setAlertStock] = useState(null);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col justify-between bg-white rounded-md shadow-md overflow-y-auto"
            style={{ maxHeight: window.innerHeight * 0.90 }}
        >

            {/* boton de salir */}
            <button
                className="absolute top-2 right-2 m-2 bg-gray-200 hover:bg-gray-300 rounded-full p-1 transition-colors"

                onClick={close}
                style={{ transition: 'background-color 0.3s' }}
            >
                <MdClose size={24} />
            </button>

            {/* boton de volver */}
            {canje &&
                <button
                    className="absolute top-2 left-2 m-2 bg-gray-200 hover:bg-gray-300 rounded-full p-1 transition-colors"

                    onClick={() => setCanje(false)}
                    style={{ transition: 'background-color 0.3s' }}
                >
                    <MdArrowBack size={24} />
                </button>
            }

            {!canje ?
                <>
                    <div className=" mt-15" style={{ height: 300, marginTop: 50 }}>
                        <img src={product.image} alt={product.name} className="object-contain w-full h-full" />
                    </div>

                    <div className="p-4 flex-1 flex flex-col">
                        <h2 className="text-lg font-semibold text-gray-900">{product.name}</h2>

                        <div className="mt-2 flex-1">
                            <ul className="list-disc list-inside">
                                {product?.description?.split('•').map((point, index) => (
                                    !isPointEmpty(point) && <li key={index} className="text-gray-700">{point.trim()}</li>
                                ))}
                            </ul>
                        </div>

                        {userDate &&
                            <div className='flex items-center mt-2'>
                                <img src={require('../../assets/sencoins.png')} className="rounded-full w-4 h-4 mr-2 object-cover" />
                                <h2 className="text-lg font-semibold text-gray-900">{(puntosDisponibles > 0 ? puntosDisponibles : 0)}</h2>
                            </div>
                        }

                        <div className='flex items-center justify-center'>

                            <img src={require('../../assets/sencoins.png')} className="rounded-full w-4 h-4 mr-2 object-cover" />

                            {precioOferta > 0 ?
                                <div className='flex'>
                                    <span className="text-gray-500 line-through text-lg mr-2">{puntosProducto}</span>
                                    <span className="text-red-500 text-lg">{precioOferta.toLocaleString(undefined, options)}</span>
                                </div>
                                :
                                <>
                                    <span className="text-lg font-semibold text-gray-900 mt-2">{t("home:texto_items_moda_puntos")} {puntosProducto}</span>
                                </>
                            }

                        </div>
                        {/* Alert sin stock */}

                        {alertStock && (
                            <div className="mt-2">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%', // Asegura que el contenedor ocupe todo el alto disponible
                                    }}
                                >
                                    <Alert variant="outlined" severity="warning">
                                        {t("home:texto_items_sin_stock")}
                                    </Alert>

                                </Box>
                            </div>
                        )}

                        {userDate && userDatePoints && (userDatePoints <= product.points) && (
                            <div className="mt-2">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Alert variant="outlined" severity="warning">
                                        {t("home:texto_items_puntos_insuficientes")}
                                    </Alert>

                                </Box>
                            </div>
                        )}

                        {newUser && (
                            <div className="mt-2">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Alert variant="outlined" severity="warning">
                                        {t("home:texto_items_puntos_insuficientes")}
                                    </Alert>

                                </Box>
                            </div>
                        )}

                        <div className="mt-2">
                            {!user ?
                                <div className="flex-1">
                                    <button onClick={() => !user ? signInWithGoogle() : setCanje(true)} style={{ backgroundColor: '#FFC300' }}
                                        className="w-full text-black px-4 py-2 rounded-md shadow-md transition duration-300 font-semibold"
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                                    >{t("header:text_login")}</button>
                                </div>
                                :
                                <div className="flex-1">
                                    {userDate && userDatePoints && (userDatePoints >= product.points ?
                                        <button style={{ backgroundColor: '#FFC300' }}
                                            className="w-full text-black px-4 py-2 rounded-md shadow-md transition duration-300 font-semibold"
                                            onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                                            onClick={async () => {
                                                await itemStock((stock) => {

                                                    if (stock > 0) {
                                                        setCanje(true);
                                                    } else {
                                                        setAlertStock(true);
                                                    }

                                                }, { id: product.idProduct });
                                            }}
                                        >{t("home:texto_items_canjear")}</button>
                                        :
                                        <button onClick={() => !user ? signInWithGoogle() : console.log('Puntos insuficientes')} style={{ backgroundColor: '#FFC300' }}
                                            className="flex items-center justify-center w-full text-black px-4 py-2 rounded-md shadow-md transition duration-300 font-semibold"
                                            onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                                        >
                                            {t("home:texto_items_no_disponible")}
                                            <MdNotInterested className="ml-2" />
                                        </button>
                                    )}

                                    {newUser && (
                                        <button onClick={() => console.log('Puntos insuficientes')} style={{ backgroundColor: '#FFC300' }}
                                            className="flex items-center justify-center w-full text-black px-4 py-2 rounded-md shadow-md transition duration-300 font-semibold"
                                            onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                                        >
                                            {t("home:texto_items_no_disponible")}
                                            <MdNotInterested className="ml-2" />
                                        </button>
                                    )}
                                </div>
                            }
                        </div>

                    </div>
                </>
                :
                <>
                    <Formulario
                        puntosProducto={product.points}
                        puntosUsuario={userDatePoints}
                        idProduct={product.idProduct}
                        nameProduct={product.name}
                        setRefreshPoints={setRefreshPoints}
                        refrshPoints={refrshPoints}
                    />
                </>
            }
        </motion.div>
    );
}

export default ProductModal;