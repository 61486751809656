import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from "framer-motion";
import ProductItem from './ProductItem';
import ProductModal from './ProductModal';
import { items } from '../../firebase/firebaseConfig';

function RenderList({ setIndex, products }) {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xs:gap-4 sm:gap-4 md:gap-8 lg:gap-20 gap-4">
            {products.map((product, i) => (
                <motion.div
                    key={product.id}
                    layoutId={product}
                >
                    <ProductItem key={product.id} product={product} setIndex={setIndex} i={i} />
                </motion.div>
            ))}
        </div>
    );
}

function ViewModal({ product, onClick }) {

    return (
        <div className="modal">
            <motion.div
                layoutId={product}
                className="xl:w-[40vw] lg:w-[55vw] md:w-[65vw] sm:w-[75vw]  w-[92vw]"
            >
                <ProductModal key={product.id} product={product} close={onClick} />
            </motion.div>
        </div>
    );
}

export default function ProductList() {

    const { t, i18n } = useTranslation();

    const [index, setIndex] = useState(false);

    const [itemsProducts, setItemsProducts] = useState([]);

    useEffect(() => {
        const checkItems = async () => {
            await items((products) => {
                if (products) {
                    setItemsProducts(products);
                }
            }, { lng: i18n.language });
        }
        checkItems();
    }, [t]);

    if (!itemsProducts) {
        return;
    }

    return (
        <>

            <RenderList setIndex={setIndex} products={itemsProducts} />

            <AnimatePresence>
                {index !== false && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.6 }}
                        exit={{ opacity: 0 }}
                        key="overlay"
                        className="overlay"
                        onClick={() => setIndex(false)}
                    />
                )}

                {index !== false && (
                    <ViewModal
                        key="image"
                        index={index}
                        product={itemsProducts[index]}
                        setIndex={setIndex}
                        onClick={() => setIndex(false)}
                    />
                )}
            </AnimatePresence>
        </>
    );
}