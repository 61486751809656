import React, { useEffect, useState } from 'react';
import { useAuth, signInWithGoogle, logout, getRedirect } from '../../firebase/firebaseConfig'
import { FiUser, FiLogOut, FiL } from 'react-icons/fi';
import { IoLanguageOutline } from "react-icons/io5";
import logoImage from '../../assets/logoDeportsen.png';
import parametros from '../parametros/parametros.json'
import MobileDetect from 'mobile-detect';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {

    const { t, i18n } = useTranslation("header");

    const navigate = useNavigate();
    const location = useLocation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsDropdownOpen(false);

        const currentPath = location.pathname;
        const newPath = currentPath.replace(/\/[a-z]{2}(\/|$)/, `/${lng}$1`);
        navigate(newPath, { replace: true });
    };

    const md = new MobileDetect(window.navigator.userAgent);

    const isMobile = md.mobile() !== null;

    const { user } = useAuth();

    useEffect(() => {
        if (user && !isMobile) {
            getRedirect();
        }
    }, [user, isMobile]);

    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center lg:px-20 md:px-8 sm:px-4 px-4" style={{ backgroundColor: parametros.backGroundColor_azul_oscuro }}>
            <img src={logoImage} alt="Logo deportsen" className="h-12 w-auto" />
            <div className="flex items-center">

                <Link to={`/home/${i18n.language}`} className="mr-4 text-white hover:text-gray-500 transition-colors">{t("text_home")}</Link>

                {user ? (
                    <div className="flex items-center">
                        <span className="mr-2">{user.displayName}</span>
                        <FiLogOut onClick={logout} className="text-xl cursor-pointer hover:text-gray-500 transition-colors" />
                    </div>
                ) : (
                    <div className="flex items-center">
                        <span className="mr-2 cursor-pointer hover:text-gray-500 transition-colors" onClick={signInWithGoogle}>{t('text_login')}</span>
                        <FiUser className="text-xl" />
                    </div>
                )}

                <div className="relative ml-4">

                    <div className="flex items-center">
                        <span className="mr-2">{t("text_language")}</span>
                        <IoLanguageOutline onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="text-xl cursor-pointer hover:text-gray-500 transition-colors" />
                    </div>

                    {isDropdownOpen && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="absolute right-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg z-10"
                            style={{ zIndex: '10000' }}
                        >
                            <ul>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('es')}>{t("language_es")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('de')}>{t("language_de")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('fr')}>{t("language_fr")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('hi')}>{t("language_hi")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('en')}>{t("language_en")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('it')}>{t("language_it")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('pt')}>{t("language_pt")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('ru')}>{t("language_ru")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('tr')}>{t("language_tr")}</li>
                                <li className="flex items-center p-2 cursor-pointer hover:bg-gray-200" onClick={() => changeLanguage('uk')}>{t("language_uk")}</li>
                            </ul>
                        </motion.div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
