import React, { useEffect, useState } from 'react';
import FaqItem from './FaqItem';
import { faq } from '../../firebase/firebaseConfig';
import { useTranslation } from 'react-i18next';

const FaqSection = () => {

    const { t, i18n } = useTranslation();

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        const checkFaqs = async () => {
            await faq((faqList) => {
                if (faqList) {
                    setFaqs(faqList);
                }
            }, { lng: i18n.language });
        }
        checkFaqs();
    }, [t]);

    return (
        <div className="text-black py-8" style={{ backgroundColor: '#33333313' }}>
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-semibold mb-4 text-center">{t("texto_preguntas_frecuentes")}</h2>
                <div className="lg:w-1/2 mx-auto">
                    {faqs && faqs.map((faq, index) => (
                        <FaqItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FaqSection;