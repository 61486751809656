import React from 'react';
import googlePlayLogo from '../../assets/googlePlay.png';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';

const ProductsSection = () => {

    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    return (
        <div className="text-black py-8 mb-3.5 px-2" style={{ backgroundColor: '#33333313' }}>
            <div className="container mx-auto">

                <h1 className="text-3xl font-bold text-center mb-4 mt-8">{t("texto_header")}</h1>

                <div className="flex justify-center mb-8">
                    <a href="https://play.google.com/store/apps/details?id=com.hernan.dev.Deportsen">
                        <img src={googlePlayLogo} alt="Google Play Logo" className="h-16" />
                    </a>
                </div>

                {/* <h2 className="text-2xl font-bold text-center mb-4">{t("texto_header_4")}</h2>

                <div className="flex justify-center mb-8">
                    <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        onClick={() => {
                            window.open("https://play.google.com/store/apps/details?id=com.hernan.dev.Deportsen", "_blank");
                        }}
                        size="large"
                    />
                </div> */}

                <h2 className="text-3xl font-bold text-center mb-4">{t("texto_header_2")}</h2>
                <p className="text-lg text-center mb-8">{t("texto_header_3")}</p>
            </div>
        </div >
    );
}

export default ProductsSection;