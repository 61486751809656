import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ProductItem = ({ product, setIndex, i }) => {

    const { t } = useTranslation();

    const isPointEmpty = (point) => {
        return point.trim() === '';
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const options = { useGrouping: true, maximumFractionDigits: 0 };
    const puntosProducto = product.points.toLocaleString(undefined, options);
    const offer = product.offer ? product.offer : 0;
    const precioOferta = product.offer ? product.points - (product.points * (offer / 100)) : 0;

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col justify-between bg-white rounded-md shadow-md overflow-hidden"
            style={{ height: '100%' }}
        >

            <div className="relative h-60 overflow-hidden">
                <img src={product.image} alt={product.name} className="object-contain w-full h-full" />
                {offer > 0 && (
                    <div className="absolute top-0 left-0 bg-red-500 text-white px-2 py-1 text-sm font-bold">
                        {offer}% OFF
                    </div>
                )}
            </div>

            <div className="p-4 flex-1 flex flex-col">
                <h2 className="text-lg font-semibold text-gray-900">{product.name}</h2>
                <div className="mt-2 flex-1">
                    <ul className="list-disc list-inside">
                        {product?.description?.split('•').map((point, index) => (
                            !isPointEmpty(point) && <li key={index} className="text-gray-700">{point.trim()}</li>
                        ))}
                    </ul>
                </div>
                <div className="mt-auto" style={{ marginTop: 20 }}>
                    <div className="flex-1">
                        <button onClick={() => setIndex(i)} style={{ backgroundColor: '#FFC300' }}
                            className="w-full text-black px-4 py-2 rounded-md shadow-md transition duration-300 font-semibold flex items-center justify-center"
                            onMouseOver={(e) => e.target.style.backgroundColor = '#EEB600'} onMouseOut={(e) => e.target.style.backgroundColor = '#FFC300'}
                        >
                            <img src={require('../../assets/sencoins.png')} className="rounded-full w-4 h-4 mr-2 object-cover" />

                            {precioOferta > 0 ?
                                <div className='flex'>
                                    <span className="text-gray-500 line-through text-lg mr-2">{puntosProducto}</span>
                                    <span className="text-red-500 text-lg">{precioOferta.toLocaleString(undefined, options)}</span>
                                </div>
                                :
                                <>
                                    <span className="text-lg">{puntosProducto}</span>
                                </>
                            }

                        </button>

                    </div>
                </div>
            </div>

        </motion.div>
    );
}

export default ProductItem;